
.app-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  color: #fff;
  z-index: 10;
  font-size: 14px;
  padding: 20px 0;
  text-align: left;
  width: 100%;
  a {
    color: #fff;
    display: inline !important;
  }
  ul {
    list-style: none;
    li {
      display: inline-block;
    }
    li + li:before {
      content: " | ";
      padding: 0 5px 0 9px;
    }
  }
}
