
login-page::part(login_page) {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}
login-page::part(email_address_label),
login-page::part(account_password_label) {
  font-weight: 500;
  display: none;
  font-size: 15px;
  max-width: 100%;
  margin-bottom: 5px;
  color: #333;
}
login-page::part(email_address),
login-page::part(account_password) {
  padding: 12px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  background-color: #ffffff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-sizing: border-box;
  cursor: text;
  display: block;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-stretch: 100%;
  font-weight: 400;
  height: 34px;
  line-height: 20px;
  outline: none;
  width: 100%;
}
login-page::part(email_address)::placeholder,
login-page::part(account_password)::placeholder  {
  color: gray;
  opacity: 1;
}
login-page::part(submit_button){
  background-image: -webkit-linear-gradient(top, #343a5e 0%, #343a5e 100%);
  background-image: -o-linear-gradient(top, #343a5e 0%, #343a5e 100%);
  background-image: linear-gradient(to bottom, #343a5e 0%, #343a5e 100%);
  background-origin: padding-box;
  background-size: auto;
  color: #ffffff;
  cursor: pointer;
  font-family: Open Sans, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-stretch: 100%;
  font-weight: 400;
  line-height: 0px;
  text-align: center;
  white-space: nowrap;
  word-spacing: 0px;
  padding: 0px;
  border: 1px solid #6e6e6e;
  border-radius: 4px;
  background-repeat: repeat-x;
  background-color: #696969;
  margin: 10px 0;
  width: 100%;
  display: block;
  height: 35px;
  box-shadow: none;
}
login-page::part(submit_button):hover {
  background: #616161;
  border-color: #616161;
  border: 1px solid #616161;
  color: #ffffff;
  border-color: 1px solid #6e6e6e;
}
login-page::part(submit_button):active {
  border: 1px solid #616161;
}
login-page::part(alert) {
  margin: 0 0 10px 0;
  padding: 5px 10px;
  background-color:#FDF4F5;
  opacity:1;
  border-style:solid;
  border-width:1px;
  border-color:#F8DDE0;
  border-radius:3px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}
login-page::part(span_error_message_credentials) {
  font-family: Open Sans, Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #A74545;
}
login-page::part(close_button) {
  color: #DB2435;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}
login-page::part(field_error) {
  font-family: Open Sans, Arial, Helvetica, sans-serif;
  display: block;
  font-size: 14px;
  color: #DB2435;
  margin: 5px 0 10px;
}
login-page::part(forgot_password) {
  display: none;
}
login-page::part(submit_login_email),
login-page::part(submit_login_password) {
  margin-bottom: 25px;
}
login-page::part(submit_login_button) {
  margin: 0px;
  text-align: right;
}
